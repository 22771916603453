import React from "react";

const Version = () => {
  return (
    <div className="version">
      <p>v0.0.6</p>
      {/* <p>ProdTEST: v0.0.6</p> */}
    </div>
  );
};

export default Version;
